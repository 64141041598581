@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .homebanner-detail div {
    width: 900px;
  }

  .homebanner-detail div .heading {
    font-size: 70px;
  }

  .home-carcount {
    padding: 160px 0 360px;
  }

  .home-price {
    padding: 90px 0;
  }

  .newslogo-box img {
    width: 300px;
  }

  .software-fixed img {
    width: 100%;
  }

  .about-app {
    padding-bottom: 80px;
  }

  .journey-slider {
    margin-right: -490px;
  }

  .labtab-content {
    height: 85%;
  }

  .carpool-start {
    padding: 100px 0;
  }

  .whitelabl-demo {
    padding: 80px 0;
    background-size: cover;
  }

  .why-wait-area.taxi-label {
    padding: 30px 0;
  }

  .taxi-solut .solut-vector {
    width: 900px;
    top: -250px;
  }

  .taxi-solut .solut-vector1 {
    width: 100px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 60%;
    right: 0;
    top: -330px;
  }

  .taxi-disbanner .taxi-vector1 {
    width: 200px;
  }

  .admi-head-image {
    width: 53%;
    height: 780px;
  }

  .admission-scr {
    margin-bottom: 100px;
  }

  .admipage-vector {
    top: -158px;
  }

  .lyfc-faq {
    padding-top: 80px;
  }

  .admipage-vector {
    top: -193px;
  }

  .admission-scr.lyft-wheel-area {
    margin: 350px 0 150px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 52%;
  }
  .restro-banner .pharm-banner-image{margin-right: 250px;}
}

@media (min-width: 1801px) and (max-width: 1919px) {
  .home-carcount {
    padding: 160px 0 360px;
  }

  .carpool-start,
  .home-price {
    padding: 90px 0;
  }

  .newslogo-box img {
    width: 300px;
  }

  .software-fixed img {
    width: 100%;
  }

  .about-app {
    padding-bottom: 70px;
  }

  .journey-slider {
    margin-right: -440px;
  }

  .labtab-content {
    height: 80%;
  }

  .whitelabl-demo {
    padding: 65px 0;
    background-size: cover;
  }

  .why-wait-area.taxi-label {
    padding: 30px 0;
  }

  .taxi-solut .solut-vector {
    width: 800px;
    top: -150px;
  }

  .taxi-solut .solut-vector1 {
    width: 100px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 60%;
    right: 0;
    top: -300px;
  }

  .taxi-disbanner .taxi-vector1 {
    width: 12%;
  }

  .admission-scr {
    margin-bottom: 100px;
  }

  .lyfc-faq {
    padding-top: 80px;
  }

  .admipage-vector {
    top: -133px;
  }

  .admission-scr.lyft-wheel-area {
    margin-top: 250px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 45%;
  }
  .restro-banner .pharm-banner-image{margin-right: 210px;}
}

@media (min-width: 1681px) and (max-width: 1800px) {
  .home-carcount {
    padding: 140px 0 330px;
  }

  .home-price {
    padding: 90px 0;
  }

  .newslogo-box img {
    width: 260px;
  }

  .software-fixed img {
    width: 90%;
  }

  .about-app {
    padding-bottom: 65px;
  }

  .journey-slider {
    margin-right: -380px;
  }

  .labtab-content {
    height: 80%;
  }

  .carpool-start {
    padding: 80px 0;
  }

  .whitelabl-demo {
    padding: 60px 0;
    background-size: cover;
  }

  .why-wait-area.taxi-label {
    padding: 30px 0;
  }

  .taxi-solut .solut-vector {
    width: 750px;
    top: -110px;
  }

  .taxi-solut .solut-vector1 {
    width: 100px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 59%;
    right: 0;
    top: -220px;
  }

  .taxi-disbanner .taxi-vector1 {
    width: 12%;
  }

  .admission-scr {
    margin-bottom: 100px;
  }

  .lyfc-faq {
    padding-top: 80px;
  }

  .admipage-vector {
    top: -196px;
  }

  .admission-scr.lyft-wheel-area {
    margin-top: 190px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 45%;
  }
  .restro-banner .pharm-banner-image{margin-right: 170px;}
}

@media (min-width: 1600px) and (max-width: 1680px) {
  .home-carcount {
    padding: 120px 0 310px;
  }

  .home-price {
    padding: 80px 0;
  }

  .newslogo-box img {
    width: 240px;
  }

  .software-fixed img {
    width: 90%;
  }

  .about-app {
    padding-bottom: 60px;
  }

  .journey-slider {
    margin-right: -370px;
  }

  .labtab-content {
    height: 70%;
  }

  .carpool-start {
    padding: 70px 0;
  }

  .whitelabl-demo {
    padding: 50px 0;
    background-size: cover;
  }

  .why-wait-area.taxi-label {
    padding: 20px 0;
  }

  .taxi-solut .solut-vector {
    width: 700px;
    top: -70px;
  }

  .taxi-solut .solut-vector1 {
    width: 100px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 59%;
    right: 0;
    top: -200px;
  }

  .taxi-disbanner .taxi-vector1 {
    width: 12%;
  }

  .admission-scr {
    margin-bottom: 100px;
  }

  .admipage-vector {
    top: -176px;
  }

  .lyfc-faq {
    padding-top: 80px;
  }

  .admission-scr.lyft-wheel-area {
    margin-top: 190px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 48%;
  }
  .restro-banner .pharm-banner-image{margin-right: 130px;}
}

@media (min-width: 1441px) and (max-width: 1599px) {
  .home-carcount {
    padding: 180px 0 220px;
  }

  .home-price {
    padding: 80px 0;
  }

  .about-app {
    padding-bottom: 60px;
  }

  .whitelabl-demo {
    padding: 45px 0;
    background-size: cover;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 59%;
    right: 0;
    top: -140px;
  }

  .taxi-disbanner .taxi-vector1 {
    width: 13%;
  }

  .taxi-solut .solut-vector1 {
    width: 100px;
    top: -70px;
  }

  .why-wait-area.taxi-label {
    padding: 30px 0;
  }

  .taxi-solut .solut-vector {
    width: 640px;
    top: -60px;
  }

  .lyfc-faq {
    padding-top: 120px;
  }

  .admipage-vector {
    top: -154px;
  }

  .admission-scr {
    margin-bottom: 40px;
  }

  .admission-scr.lyft-wheel-area {
    margin-top: 190px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 48%;
  }
  .restro-banner .pharm-banner-image{margin-right: 120px;}
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .home-carcount {
    padding: 130px 0 220px;
  }

  .whitelabl-demo {
    padding: 50px 0;
    background-size: cover;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 60%;
    right: 0;
    top: -60px;
  }

  .why-wait-area.taxi-label {
    padding: 30px 0;
  }

  .taxi-solut .solut-vector {
    width: 560px;
    top: 10px;
  }

  .lyft-wheel-area .admi-head-image {
    width: 48%;
  }
  .restro-banner .pharm-banner-image{margin-right: 80px;}
}

@media (min-width: 1300px) and (max-width: 1366px) {
  .home-carcount {
    padding: 100px 0 220px;
  }

  .about-app {
    padding-bottom: 50px;
  }

  .journey-slider {
    padding-right: 130px;
  }

  .taxi-solut .solut-vector {
    width: 550px;
    top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .teamlogo-box div img {
    width: 100px;
  }

  .homebanner-detail .img-responsive {
    height: 450px;
  }

  .busstab-detail h4 {
    font-size: 34px;
  }

  .platform-box div h4 {
    font-size: 22px;
  }

  .newslogo-box img {
    width: 180px;
  }

  .footer-app,
  .footer-logo {
    padding-right: 0;
  }

  .journey-slider {
    margin-right: -350px;
    padding-right: 330px;
  }

  .future-iconbox {
    min-height: 211px;
  }

  .carpool-head .heading {
    font-size: 44px;
  }

  .carsolution-model h3,
  .carsolution-model h4,
  .carvalue-box h3,
  .carvalue-box h4,
  .carvehicle-box h3,
  .carvehicle-box h4 {
    font-size: 20px;
  }

  .carvehicle-box ul {
    width: 83%;
  }

  .carsolution-model ul li {
    margin-top: 19px;
  }

  .carpool-tech .center-heading br {
    display: none;
  }

  .carvalue-box {
    padding: 15px;
  }

  .carfeature-box p {
    min-height: 126px;
  }

  .whitetab-content:after {
    width: 450px;
    height: 450px;
    right: -50px;
  }

  .whiteplat-box {
    min-height: 216px;
  }

  .whitelabl-demo .heading {
    font-size: 31px;
  }

  .header-menulink .dropdown-menu {
    width: 940px;
  }

  .solution-menubox {
    margin: 0 9px 22px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 65%;
    top: 0;
  }

  .taxi-solut .solut-vector {
    width: 460px;
    top: 110px;
  }

  .taxi-service .web-booking-box p {
    min-height: 85px;
  }

  .admipage-vector {
    top: -55px;
  }

  .slider-section {
    min-height: 398px;
  }

  .slider-section .slick-slide {
    padding: 30px 10px;
  }

  .lyft-enterprises .enterprises-box p {
    min-height: 126px;
  }

  .admission-scr.lyft-wheel-area {
    margin: 40px 0;
    padding: 0;
  }

   /* restaurant page */

   .restro-banner .pharm-banner-image{margin-right: 10px;}
   .restro-industry-card{min-height: 85px;}
   .restro-industry-head span{display: inline;}
   .elearning-head .heading span{display: inline;}
   .restro-excellent-box{padding:20px 15px;}
   .restro-excellent-box p{min-height: 105px;}
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .teamlogo-box div img {
    width: 110px;
  }

  .labtab-content {
    top: 90px;
  }

  .busstab-detail h4 {
    font-size: 40px;
  }

  .platform-box div h4 {
    font-size: 26px;
  }

  .newslogo-box img {
    width: 200px;
  }

  .footer-app,
  .footer-logo {
    padding-right: 30px;
  }

  .carpool-head .heading {
    font-size: 50px;
  }

  .carsolution-model h3,
  .carsolution-model h4,
  .carvehicle-box h3,
  .carvehicle-box h4 {
    font-size: 24px;
  }

  .carvehicle-box ul {
    width: 65%;
  }

  .carsolution-model ul li {
    margin-top: 28px;
  }

  .carvalue-box {
    padding: 22px;
  }

  .carvalue-box h3,
  .carvalue-box h4 {
    font-size: 22px;
  }

  .carfeature-box p {
    min-height: 84px;
  }

  .whitetab-content:after {
    width: 550px;
    height: 550px;
    right: -80px;
  }

  .whiteplat-box {
    min-height: auto;
  }

  .whitelabl-demo .heading {
    font-size: 38px;
  }

  .header-menulink .dropdown-menu {
    width: 1120px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 60%;
    top: 0;
  }

  .taxi-solut .solut-vector {
    width: 520px;
    top: 50px;
  }

  /* restaurant page */
  .restro-industry-card{min-height: 110px;}
  .restro-industry-head span{display: block;}
  .elearning-head .heading span{display: block;}
  .restro-excellent-box{padding:30px;}
  .restro-excellent-box p{min-height: auto;}
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading {
    font-size: 40px;
  }

  .header-menulink li {
    margin-right: 15px;
  }

  .homebanner-detail .img-responsive {
    height: 380px;
  }

  .header-butn {
    padding: 8px 12px;
  }

  .google-translate-drop {
    font-size: 11px;
    width: 140px;
  }

  .homebanner-detail div {
    width: 600px;
  }

  .homebanner-detail div .heading {
    font-size: 42px;
  }

  .labtab-link .nav-link div h4 {
    font-size: 16px;
    line-height: 1.4;
  }

  .labtab-link .nav-link span {
    font-size: 60px;
  }

  .labtab-content {
    padding: 30px 20px 30px 45px;
    margin-left: -45px;
    top: 90px;
    height: auto;
  }

  .labtab-content .tab-pane h4 {
    font-size: 28px;
  }

  .labtab-iconbox {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .labtab-iconbox div {
    width: 48%;
    margin-top: 25px;
  }

  .labtab-iconbox div img {
    width: 34px;
  }

  .labtab-iconbox div span {
    font-size: 13px;
    letter-spacing: 0;
  }

  .home-solution {
    padding-top: 0;
  }

  .software-fixed {
    top: 90px;
  }

  .appbox-image .img-responsive,
  .software-fixed img {
    width: 100%;
  }

  .software-box h4,
  .taxi-service .web-booking-box h4 {
    font-size: 24px;
  }

  .home-businesstab .sub-heading {
    width: 70%;
  }

  .businesstab-link .nav-item {
    width: 17%;
  }

  .businesstab-link .nav-link {
    padding: 15px 8px;
  }

  .businesstab-link .nav-link img {
    width: 40px;
  }

  .businesstab-link .nav-link p {
    font-size: 12px;
  }

  .businesstab-content,
  .carsolution-model,
  .carvehicle-box {
    padding: 20px;
  }

  .busstab-detail img {
    width: 150px;
  }

  .busstab-detail h4 {
    font-size: 30px;
  }

  .platform-box div h4 {
    font-size: 25px;
  }

  .testimonial-slider {
    padding-bottom: 30px;
  }

  .testi-bigphoto {
    padding: 10px;
    margin-right: 0;
  }

  .footer-logo img,
  .newslogo-box img {
    width: 180px;
  }

  .footer-app,
  .footer-logo {
    padding-right: 0;
  }

  .footer-social li {
    margin: 0 10px;
  }

  .footer-app {
    flex-direction: column;
  }

  .footer-app div:first-child {
    width: 100%;
    flex-direction: row;
  }

  .footer-app div:first-child img {
    width: 95px;
  }

  .footer-app div:nth-child(2) {
    margin-top: 10px;
    width: 100%;
  }

  .about-carring {
    bottom: 6px;
  }

  .carring-blur {
    width: 300px;
    height: 300px;
  }

  .empower-box {
    padding: 20px 8px;
  }

  .carlaunch-box .heading,
  .empower-box h4 {
    font-size: 32px;
  }

  .journey-slider {
    margin-right: -260px;
    padding-right: 220px;
  }

  .about-team .heading {
    margin-bottom: 10px;
  }

  .aboutteam-box {
    margin-top: 30px;
  }

  .carpool-head .row {
    padding: 60px 0;
  }

  .carpool-head .heading,
  .whitelabl-head .heading {
    font-size: 38px;
  }

  .carpool-head label,
  .whitehead-detail label,
  .whitelabl-demo .heading,
  .whitemobilz-box h3,
  .whitemobilz-box h4 {
    font-size: 26px;
  }

  .carlaunch-box p {
    margin-bottom: 15px;
  }

  .carpool-solution .center-heading br,
  .carpool-tech .center-heading br,
  .labtab-link .nav-link br,
  .whitelabl-solut.taxi-solut .sub-heading br {
    display: none;
  }

  .carsolution-model ul li {
    padding: 14px;
    width: 48%;
  }

  .cartechtab-link .nav-item {
    margin-bottom: 20px;
  }

  .cartechtab-content {
    padding: 20px;
    margin-left: 0;
    margin-top: 30px;
  }

  .carwork-box label {
    font-size: 20px;
    line-height: 1.3;
  }

  .carwork-box span {
    font-size: 50px;
    margin-right: 10px;
  }

  .carwork-vector-1 {
    width: 480px;
  }

  .carwork-vector-3 {
    width: 110px;
  }

  .carwork-vector-4 {
    width: 120px;
  }

  .carvalue-box {
    min-height: 214px;
  }

  .carpool-start {
    padding: 50px 0;
  }

  .carfeature-box p {
    min-height: auto;
  }

  .faq-accord .accordion-body {
    padding: 0 0 15px;
  }

  .faq-accord .card:before {
    width: 100.5%;
  }

  .whitehead-detail span {
    font-size: 16px;
  }

  .whitehead-vector-1 {
    top: -20px;
    width: 300px;
  }

  .whitehead-vector-2 {
    top: 0;
    width: 250px;
  }

  .whitelabl-tabarea .sub-heading {
    width: 80%;
    font-size: 20px;
  }

  .whitetab-link .nav-link {
    padding: 15px;
  }

  .whitetab-link .nav-link p {
    font-size: 14px;
    margin: 9px 0 0;
  }

  .white-insidetab h3,
  .white-insidetab h4,
  .whitetab-detail h3,
  .whitetab-detail h4 {
    font-size: 34px;
  }

  .whitetab-content {
    padding: 20px 20px 0;
  }

  .whitetab-content:after {
    width: 380px;
    height: 380px;
    bottom: -30%;
  }

  .labelinside-tablink {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .labelinside-tablink .nav-item {
    margin-bottom: 15px;
    width: 22%;
  }

  .mobsol-accord .accordion-button {
    padding: 15px;
    font-size: 20px;
  }

  .mobsol-accord .accordion-body {
    padding: 0 15px 15px;
  }

  .whitelabl-demo {
    background-size: cover;
  }

  .query-popup .modal-lg {
    max-width: 740px;
  }

  .query-formbox {
    padding: 30px 20px;
  }

  .featurehead-vector-2 {
    width: 70px;
  }

  .header-menulink .dropdown-menu {
    width: 700px;
    padding: 18px 10px 0;
  }

  .solution-bigmenu {
    justify-content: start;
  }

  .solution-menubox {
    width: 30%;
    margin: 0 10px 22px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 70%;
    top: 0;
  }

  .taxi-solut .solut-vector {
    width: 50%;
    top: 180px;
  }

  .admi-head-image {
    width: 41%;
    height: 300px;
  }

  .admipage-vector {
    top: 0;
  }

  .slider-section {
    padding-bottom: 0;
    min-height: 245px;
  }

  .slider-section .slick-slide {
    padding: 40px;
  }

  .lft-banner .heading strong {
    display: inline;
    padding-left: 4px;
  }

  .admission-scr.lyft-wheel-area {
    margin: 0;
    padding: 0;
  }

   /* restaurant page */
   .restro-banner .pharm-banner-image{margin-right: 10px;}
   .restro-industry-head span{display: inline;}
   .restro-industry-card{min-height: 140px;}
   .restro-magic-details h4{font-size: 32px;}
   .elearning-head .heading span{display: inline;}
   .restro-margin-count{margin-top: 50px;}
   .restro-excellent-box p{min-height: 105px;}
   .restro-magic-details, .restro-magic-img{margin-top: 60px;}
}

@media (min-width: 600px) and (max-width: 767px) {
  .home-price h5,
  .labtab-content .tab-pane label {
    letter-spacing: 1px;
  }

  .heading,
  .whitelabl-demo .heading {
    font-size: 34px;
  }

  .header-gap {
    margin-top: 83px;
  }

  .header-area {
    padding: 15px 0;
  }

  .header-butn {
    display: none;
  }

  .stick {
    padding: 12px 0;
  }

  .about-carring,
  .about-future .heading br,
  .about-reason .heading br,
  .carpool-custom .heading br,
  .carpool-feature .heading br,
  .carpool-setupbox .heading br,
  .carpool-solution .center-heading br,
  .carpool-tech .center-heading br,
  .carwork-vector-1,
  .header-menulink,
  .home-businesstab .heading br,
  .home-solution .heading br,
  .querypop-image,
  .web-booking-area.taxi-service .heading br,
  .web-booking-box h3 br,
  .web-booking-box h4 br,
  .whitelabl-mobilize .heading br,
  .whitelabl-platform .heading br,
  .whitetab-link .nav-link p br {
    display: none;
  }

  .homebanner-detail .img-responsive {
    height: 350px;
  }

  .homebanner-detail div {
    width: 570px;
    padding: 0 15px;
  }

  .homebanner-detail div .heading {
    font-size: 32px;
  }

  .homebanner-detail div p {
    font-size: 16px;
    display: block;
  }

  .homebanner-detail div p span {
    font-size: 16px;
    margin: 0;
  }

  .home-teamlogo {
    margin: 50px 0 20px;
  }

  .home-teamlogo .heading {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .teamlogo-box div {
    height: 60px;
  }

  .carwork-vector-3,
  .teamlogo-box div img {
    width: 80px;
  }

  .home-carcount {
    padding: 50px 0 220px;
  }

  .home-carcount .heading {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .carcount-box img {
    width: 22px;
  }

  .carcount-box div h4 {
    font-size: 45px;
  }

  .carcount-box div h4 small {
    font-size: 36px;
  }

  .carcount-box div p {
    letter-spacing: 0.5px;
  }

  .carvector img {
    width: 200px;
  }

  .about-future,
  .carpool-feature,
  .carpool-vehicle,
  .home-global,
  .home-labtool,
  .home-testimonial,
  .whitelabl-mobilize {
    margin: 50px 0;
  }

  .about-empower .heading,
  .about-team .heading,
  .home-labtool .sub-heading {
    margin-bottom: 10px;
  }

  .labtab-link .nav-link span {
    font-size: 60px;
  }

  .labtab-content {
    padding: 22px;
    margin: 20px 0 0;
    height: max-content;
  }

  .global-countbox div h4,
  .journey-left h4,
  .journey-right h4,
  .labtab-content .tab-pane h4,
  .whitemobilz-box h3,
  .whitemobilz-box h4 {
    font-size: 28px;
  }

  .labtab-iconbox {
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .labtab-iconbox div {
    width: 48%;
    margin-top: 25px;
  }

  .labtab-iconbox div img {
    width: 34px;
  }

  .home-solution {
    padding-top: 0;
    margin-bottom: 50px;
  }

  .about-app .sub-heading,
  .about-journey .heading,
  .carlaunch-box,
  .home-global .text-center,
  .home-platform .heading,
  .home-software .heading,
  .home-solution .heading {
    margin-bottom: 30px;
  }

  .solution-vector {
    object-fit: cover;
    height: 250px;
  }

  .carfeature-box p,
  .carvalue-box,
  .future-iconbox,
  .reason-box p,
  .solution-box div p {
    min-height: auto;
  }

  .carvehicle-box,
  .software-fixed img,
  .web-book-back,
  .whitetab-link .nav-item {
    width: 100%;
  }

  .software-box {
    padding: 20px 20px 20px 70px;
    margin: 30px 0 0;
  }

  .software-box h4,
  .taxi-label .why-wait-content .heading {
    font-size: 24px;
  }

  .booking-sec,
  .carpool-work,
  .featurepage-head,
  .home-businesstab,
  .web-booking-area.taxi-service {
    padding: 50px 0;
  }

  .carpool-setupbox p,
  .cartechtab-head p,
  .home-businesstab .sub-heading,
  .whitelabl-solut .sub-heading {
    width: auto;
  }

  .businesstab-link {
    flex-wrap: wrap !important;
  }

  .businesstab-link .nav-item {
    width: 33%;
  }

  .businesstab-link .nav-link {
    padding: 10px 6px;
  }

  .businesstab-link .nav-link img {
    width: 36px;
  }

  .businesstab-link .nav-link p {
    font-size: 13px;
    margin: 6px 0 0;
  }

  .businesstab-content {
    padding: 20px;
  }

  .busstab-detail img {
    width: 140px;
  }

  .busstab-detail h4 {
    font-size: 30px;
  }

  .busstab-image img {
    box-shadow: 0 2px 11px 4px rgb(40 44 63 / 0.06);
    margin-top: 30px;
  }

  .platform-box div h4,
  platform-box div h3 {
    font-size: 26px;
  }

  .testimonial-slider {
    padding-bottom: 30px;
  }

  .testi-bigphoto,
  .whiteimage-strech {
    margin-right: 0;
  }

  .testi-content img {
    width: 30px;
    margin: 12px 0 8px;
  }

  .testi-content p {
    line-height: 1.6;
  }

  .testimonial-slider .slick-dots {
    text-align: center;
    padding-left: 0;
  }

  .home-price {
    background-position: right;
    padding: 50px 0;
  }

  .home-price h5 {
    font-size: 14px;
  }

  .global-haed h4 {
    font-size: 18px;
  }

  .carsolution-vehicle,
  .global-count {
    flex-direction: column;
  }

  .global-countbox {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .global-countbox div {
    justify-content: center;
  }

  .carpool-head h1,
  .carpool-head label,
  .global-countbox div small {
    font-size: 22px;
  }

  .map-vector {
    margin: 30px auto 0;
  }

  .carpool-faq,
  .home-newsarea,
  .why-wait-area.taxi-label {
    margin-bottom: 50px;
  }

  .carsolution-model h3,
  .carsolution-model h4,
  .home-newsarea .heading {
    margin-bottom: 12px;
  }

  .newslogo-box img {
    width: 130px;
    box-shadow: 0 2px 4px 2px rgb(40 44 63 / 0.04);
  }

  .footer-app,
  .footer-logo,
  .journey-slider {
    padding-right: 0;
  }

  .footer-logo img {
    width: 180px;
    margin: 0 auto;
    display: block;
  }

  .footer-logo p {
    margin: 8px 0 0;
    text-align: center;
  }

  .footer-app div:nth-child(2) {
    width: 42%;
  }

  .aboutteam-box,
  .carpool-custom .img-responsive,
  .footer-links,
  .taxi-solut .moblimage-box {
    margin-top: 30px;
  }

  .footer-links h2,
  .footer-links h3 {
    font-size: 16px;
  }

  .footer-links li {
    margin-bottom: 13px;
  }

  .footer-links li a,
  .footer-social li a {
    font-size: 14px;
  }

  .footer-copyright {
    margin-top: 25px !important;
    padding: 10px 0;
  }

  .footer-country,
  .footer-privacy {
    font-size: 13px;
    letter-spacing: 0;
  }

  .footer-social li {
    margin: 0 7px;
  }

  .about-future .heading,
  .carpool-work .center-heading {
    margin-bottom: 0;
  }

  .about-app {
    padding-bottom: 0;
  }

  .appbox-left {
    margin-bottom: 25px;
  }

  .appbox-blue,
  .appbox-white {
    width: 210px;
    margin: 0 auto;
  }

  .appbox-right {
    margin-top: 25px;
  }

  .carring-blur {
    width: 100%;
    top: 55%;
    bottom: auto;
    transform: translateY(-50%);
    height: 250px;
  }

  .appbox-image {
    margin: 40px 0 30px;
    padding-bottom: 40px;
    position: relative;
  }

  .appbox-image .img-responsive {
    width: 250px;
  }

  .mobile-carring {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12px;
    z-index: -1;
  }

  .about-empower {
    padding: 50px 0 60px;
    margin: 50px 0;
  }

  .empower-box {
    padding: 20px;
    margin-top: 30px;
  }

  .carlaunch-box .heading,
  .empower-box h4 {
    font-size: 38px;
  }

  .custom-arrows {
    margin-top: 22px;
  }

  .custom-arrows .default-arrowleft,
  .custom-arrows .default-arrowright {
    margin: 0 10px;
  }

  .about-reason,
  .about-team {
    margin: 40px 0 50px;
  }

  .carpool-head .row {
    padding: 40px 0;
  }

  .carpool-head p {
    margin: 10px 0 15px;
  }

  .carhead-image {
    position: unset;
    width: 70%;
    margin: 0 auto;
    display: block;
  }

  .carpool-head .default-btn {
    margin-right: 10px;
  }

  .carpool-head .row img,
  .carsolution-model,
  .carvehicle-box ul li {
    margin-top: 20px;
  }

  .carlaunch-box p {
    margin-bottom: 15px;
  }

  .cartechtab-link .nav-item,
  .cartechtab-point:last-child {
    margin-bottom: 20px;
  }

  .cartechtab-image-2 {
    width: 80%;
    float: right;
  }

  .carvehicle-box ul {
    width: 60%;
  }

  .carvehicle-arrow {
    margin-left: 0;
    margin-top: 20px;
    transform: rotate(90deg);
  }

  .carsolution-model ul li {
    margin-top: 13px;
    width: 48.5%;
  }

  .carsolution-model ul li p {
    margin: 12px 0 0;
  }

  .cartechtab-content {
    padding: 20px;
    margin-left: 0;
    margin-top: 30px;
  }

  .cartechtab-image {
    width: 260px;
  }

  .cartechtab-btn {
    margin-top: 20px !important;
  }

  .carpool-custom {
    margin-top: 50px;
  }

  .carpool-custom p {
    margin: 8px 0 15px;
  }

  .carcustom-vector {
    width: 80%;
  }

  .carwork-box,
  .whitelabl-tabarea {
    margin-top: 40px;
  }

  .carwork-box img {
    width: 75%;
  }

  .carwork-vector-2 {
    width: 180px;
  }

  .carwork-vector-4 {
    width: 90px;
  }

  .carpool-value .sub-heading {
    margin: 8px 0 0;
  }

  .carpool-start {
    padding: 50px 0;
    margin: 50px 0;
    background-size: 120% 100%;
  }

  .carpool-feature .sub-heading {
    margin: 8px 0 30px;
  }

  .carfeature-more a {
    margin: 0 5px;
  }

  .faq-accord .accordion-body {
    padding: 0 0 15px;
  }

  .faq-accord .accordion-button {
    line-height: 1.4;
    font-size: 15px;
  }

  .faq-accord .card:before {
    width: 100.8%;
  }

  .whitehead-detail label {
    font-size: 22px;
    margin-bottom: 2px;
  }

  .whitehead-detail span {
    margin: 3px 0 8px;
    font-size: 16px;
  }

  .whitehead-image {
    width: 90%;
    margin: 30px auto 0;
  }

  .whitehead-vector-1 {
    top: -60px;
  }

  .whitehead-vector-2 {
    top: 170px;
  }

  .whitelabl-tabarea .sub-heading {
    width: auto;
    font-size: 16px;
  }

  .whitetab-link {
    margin-top: 30px;
    flex-direction: column;
    background-size: contain;
    background-repeat: repeat;
  }

  .whitetab-link .nav-link {
    display: flex;
    align-items: center;
  }

  .whitetab-link .nav-link p {
    font-size: 20px;
    margin: 0 0 0 10px;
  }

  .whitetab-link .nav-item:after {
    width: 90%;
    height: 1.4px;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: none;
    background: linear-gradient(to right, #fff0, #0035e5, #fff0);
  }

  .whitetab-content {
    margin-top: 20px;
    padding: 20px 20px 0;
  }

  .white-insidetab h3,
  .white-insidetab h4,
  .whitetab-detail h3,
  .whitetab-detail h4 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .app-butn img {
    width: 100px;
  }

  .whitemobilz-box {
    margin-top: 10px;
  }

  .whitelabl-platform {
    padding: 50px 0;
    margin-bottom: 50px;
  }

  .mobsol-accord .card {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .whitelabl-demo {
    margin: 50px 0;
    background-position: top left;
    background-size: cover;
  }

  .labelinside-tablink {
    flex-direction: row;
    justify-content: space-between;
  }

  .labelinside-tablink .nav-item {
    margin-bottom: 10px;
    width: 31%;
  }

  .wlabel-tab {
    margin-top: 30px !important;
  }

  .query-popup {
    padding: 0 !important;
  }

  .query-popup .modal-lg {
    max-width: 500px;
  }

  .query-formbox .heading {
    font-size: 34px;
    margin-bottom: 15px;
  }

  .pop-vector-3 {
    top: -30px;
    width: 300px;
  }

  .query-formbox .half-field {
    width: 100%;
    display: block;
    padding: 0 !important;
  }

  .whitelabl-solut.taxi-solut {
    padding-bottom: 50px;
  }

  .featurehead-vector-1 {
    top: -100px;
    width: 500px;
  }

  .featurehead-vector-2 {
    width: 50px;
  }

  .featurtable-vector {
    width: 60px;
  }

  .feat-heading td:first-child {
    font-size: 14px;
    line-height: 1.3;
  }

  .feature-table table tr td {
    padding: 6px;
    font-size: 13px;
  }

  .feat-heading td:last-child,
  .feat-heading td:nth-last-child(2) {
    font-size: 12px;
    padding: 5px 0;
    width: 60px !important;
  }

  .feature-table table .table-HEAD td {
    font-size: 15px;
  }

  .feature-table table tr td:first-child {
    width: 35px;
  }

  .feature-table table tr td img {
    width: 12px;
  }

  .feature-table table .table-HEAD td:first-child:before {
    right: 16px;
    width: 30px;
  }

  .taxi-disbanner .taxi-vector2 {
    width: 75%;
    top: 0;
  }

  .taxi-solut .solut-vector1 {
    width: 50px;
    top: -50px;
  }

  .taxi-solut .solut-vector {
    width: 54%;
    top: unset;
    bottom: 0;
  }

  .admi-head-image {
    position: relative;
    width: 100%;
    top: auto;
    bottom: 0;
    transform: none;
    right: 0;
    margin: 30px auto 0;
    height: auto;
  }

  .slider-section {
    padding-bottom: 0;
    min-height: auto;
  }

  .lyft-enterprises {
    padding: 50px 0;
    padding-bottom: 50px;
  }

  .lyft-banner-btn {
    margin-bottom: 30px;
  }

  .admission-scr {
    padding: 50px 0;
    padding-top: 50px;
  }

  .admipage-vector {
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
  }

  .admipage-vector2 {
    display: none;
  }

  .lyfc-faq {
    padding-top: 0;
  }

  .lyft-banner-btn {
    margin-bottom: 30px;
  }

  .admission-scr.lyft-wheel-area {
    margin: 0;
    padding-top: 0;
  }

  .lyft-wheel-content .heading br {
    display: none;
  }

  .slider-section .slick-slide {
    padding: 30px 20px;
  }

  .media-section {
    padding: 50px 0;
  }

  /* restaurant page */
  .restro-banner.pharm-banner{padding-top: 30px;}
  .restro-industry-area{margin: 50px 0;}
  .restro-industry-card{min-height: 150px;}
  .restro-magic-details{margin-top: 20px;}
  .restro-magic-details h4{font-size: 24px;}
  .restro-magic-details .purple-gradiant{font-size: 12px;}
  .restro-margin-area{margin: 50px 0;}
  .restro-margin-head span{display: inline;}
  .restro-margin-count{margin: 50px 0;}
  .restro-margin-detail{margin-bottom: 20px;}
  .elearning-head .heading span{display: inline;}
  .restro-excellent-area{margin: 50px 0;}
  .restro-excellent-box{margin-top: 30px;}
}

@media (max-width: 599px) {
  .carfeature-box p,
  .carvalue-box,
  .future-iconbox,
  .reason-box p,
  .solution-box div p {
    min-height: auto;
  }

  .carlaunch-box .heading,
  .global-countbox div h4,
  .heading {
    font-size: 28px;
  }

  .header-gap {
    margin-top: 75px;
  }

  .default-btn,
  .default-white,
  .transparent-btn {
    padding: 10px 15px 9px;
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  .default-arrowleft,
  .default-arrowright {
    width: 42px;
    height: 42px;
  }

  .default-arrowleft img,
  .default-arrowright img {
    width: 11px;
  }

  .header-area {
    padding: 13px 0;
  }

  .stick {
    padding: 10px 0;
  }

  .logo img {
    width: 130px;
  }

  .stick .logo img {
    width: 125px;
  }

  .header-butn {
    display: none;
    padding: 7px 10px;
    letter-spacing: 0.5px;
  }

  .google-translate-drop {
    width: 125px;
    font-size: 10px;
    padding: 10px 5px;
    margin-left: 0;
  }

  .btn1 {
    width: 36px;
    padding: 9px;
  }

  .about-carring,
  .about-future .heading br,
  .about-reason .heading br,
  .carpool-custom .heading br,
  .carpool-feature .heading br,
  .carpool-setupbox .heading br,
  .carpool-solution .center-heading br,
  .carpool-tech .center-heading br,
  .carwork-vector-1,
  .header-menulink,
  .home-businesstab .heading br,
  .home-solution .heading br,
  .querypop-image,
  .taxi-disbanner .heading br,
  .taxi-disbanner p br,
  .taxi-label .why-wait-content .heading br,
  .taxi-service .web-booking-box h3 br,
  .taxi-service .web-booking-box h4 br,
  .web-booking-area.taxi-service .heading br,
  .whitelabl-mobilize .heading br,
  .whitelabl-platform .heading br,
  .whitetab-link .nav-link p br {
    display: none;
  }

  .homebanner-detail .img-responsive {
    object-fit: cover;
    width: 100%;
    height: 350px;
  }

  .homebanner-detail div {
    width: 100%;
    padding: 0 15px;
  }

  .busstab-detail h3,
  .busstab-detail h4,
  .homebanner-detail div .heading,
  .journey-left h4,
  .journey-right h4,
  .labtab-content .tab-pane h4 {
    font-size: 26px;
  }

  .homebanner-detail div p {
    font-size: 15px;
    display: block;
  }

  .homebanner-detail div p span {
    font-size: 15px;
    margin: 0;
  }

  .home-teamlogo {
    margin: 50px 0 20px;
  }

  .home-teamlogo .heading {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .teamlogo-box div {
    height: 60px;
  }

  .carwork-vector-3,
  .teamlogo-box div img {
    width: 80px;
  }

  .home-carcount {
    padding: 50px 0 220px;
  }

  .home-carcount .heading {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .carcount-box,
  .carsolution-vehicle,
  .global-count {
    flex-direction: column;
  }

  .carcount-box img {
    width: 22px;
    margin: 0 0 15px;
  }

  .carcount-box div h4 {
    font-size: 40px;
  }

  .carcount-box div h4 small,
  .empower-box h4 {
    font-size: 32px;
  }

  .carcount-box div p {
    font-size: 13px;
    letter-spacing: 0.5px;
    margin: 8px 0 0;
  }

  .carvector img {
    width: 200px;
  }

  .about-future,
  .carpool-feature,
  .carpool-vehicle,
  .home-global,
  .home-labtool,
  .home-testimonial,
  .whitelabl-mobilize {
    margin: 50px 0;
  }

  .about-empower .heading,
  .about-team .heading,
  .footer-links h2,
  .footer-links h3,
  .home-labtool .sub-heading,
  .taxi-disbanner .heading {
    margin-bottom: 10px;
  }

  .labtab-link .nav-link span {
    font-size: 60px;
  }

  .carwork-box img,
  .labtab-link .nav-link div {
    width: 70%;
  }

  .labtab-link .nav-link div img {
    width: 40px;
    margin-right: 8px;
  }

  .labtab-link .nav-link div h3,
  .labtab-link .nav-link div h4 {
    font-size: 16px;
    line-height: 1.4;
  }

  .labtab-content {
    padding: 22px;
    margin: 20px 0 0;
    height: max-content;
  }

  .labtab-content .tab-pane label {
    letter-spacing: 1px;
  }

  .labtab-iconbox {
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .labtab-iconbox div {
    width: 48%;
    margin-top: 25px;
  }

  .labtab-iconbox div img {
    width: 34px;
  }

  .home-solution {
    padding-top: 0;
    margin-bottom: 50px;
  }

  .about-app .sub-heading,
  .about-journey .heading,
  .carlaunch-box,
  .home-global .text-center,
  .home-platform .heading,
  .home-software .heading,
  .home-solution .heading {
    margin-bottom: 30px;
  }

  .solution-vector {
    object-fit: cover;
    height: 250px;
  }

  .carpool-head h1,
  .carpool-head label,
  .carwork-box label,
  .reason-box h3,
  .reason-box h4,
  .solution-box div h3,
  .solution-box div h4 {
    font-size: 20px;
  }

  .img-responsive.banner-img3,
  .software-fixed img,
  .taxi-solut .moblimage-item img,
  .whitetab-link .nav-item {
    width: 100%;
  }

  .software-box {
    padding: 15px 15px 15px 60px;
    margin: 30px 0 0;
  }

  .software-box label {
    font-size: 18px;
    width: 45px;
    height: 45px;
  }

  .software-box h3,
  .software-box h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .booking-sec,
  .carpool-work,
  .featurepage-head,
  .home-businesstab,
  .web-booking-area.taxi-service {
    padding: 50px 0;
  }

  .carpool-setupbox p,
  .cartechtab-head p,
  .home-businesstab .sub-heading,
  .whitelabl-solut .sub-heading {
    width: auto;
  }

  .businesstab-link {
    flex-wrap: wrap !important;
  }

  .businesstab-link .nav-item {
    width: 33%;
  }

  .businesstab-link .nav-link {
    padding: 10px 6px;
  }

  .businesstab-link .nav-link img {
    width: 36px;
  }

  .businesstab-link .nav-link p {
    font-size: 13px;
    margin: 6px 0 0;
  }

  .businesstab-content {
    padding: 20px;
  }

  .busstab-detail img {
    width: 140px;
  }

  .busstab-image img {
    box-shadow: 0 2px 11px 4px rgb(40 44 63 / 0.06);
    margin-top: 30px;
  }

  .platform-box div h3,
  .platform-box div h4,
  .whitemobilz-box h3,
  .whitemobilz-box h4 {
    font-size: 24px;
  }

  .testimonial-slider {
    padding-bottom: 30px;
  }

  .testi-bigphoto,
  .whiteimage-strech {
    margin-right: 0;
  }

  .testi-content img {
    width: 30px;
    margin: 12px 0 8px;
  }

  .testi-content p {
    line-height: 1.6;
  }

  .testimonial-slider .slick-dots {
    text-align: center;
    padding-left: 0;
  }

  .home-price {
    background-position: right;
    padding: 50px 0;
  }

  .home-price h3,
  .home-price h5 {
    font-size: 14px;
    letter-spacing: 1px;
  }

  .global-haed h3,
  .global-haed h4 {
    font-size: 18px;
  }

  .global-countbox {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .global-countbox div {
    justify-content: center;
  }

  .cartechtab-head h4,
  .global-countbox div small,
  .taxi-label .why-wait-content .heading {
    font-size: 22px;
  }

  .map-vector {
    margin: 22px auto 0;
  }

  .carpool-faq,
  .home-newsarea {
    margin-bottom: 50px;
  }

  .carsolution-model h3,
  .carsolution-model h4,
  .home-newsarea .heading {
    margin-bottom: 12px;
  }

  .newslogo-box img {
    width: 112px;
    box-shadow: 0 2px 4px 2px rgb(40 44 63 / 0.04);
  }

  .footer-app,
  .footer-logo {
    padding-right: 0;
  }

  .footer-logo img {
    width: 160px;
    margin: 0 auto;
    display: block;
  }

  .footer-logo p {
    margin: 8px 0 0;
    text-align: center;
  }

  .footer-app div:first-child {
    width: 32%;
  }

  .footer-app div:nth-child(2) {
    width: 52%;
  }

  .appbox-right,
  .footer-links {
    margin-top: 25px;
  }

  .footer-links li {
    margin-bottom: 13px;
  }

  .footer-copyright {
    margin-top: 25px !important;
    padding: 10px 0;
  }

  .footer-country,
  .footer-privacy {
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  .footer-social li {
    margin: 0 13px;
  }

  .about-future .heading,
  .carpool-work .center-heading {
    margin-bottom: 0;
  }

  .future-iconbox {
    padding: 25px;
  }

  .about-app {
    padding-bottom: 0;
  }

  .appbox-left {
    margin-bottom: 25px;
  }

  .appbox-blue,
  .appbox-white {
    width: 210px;
    margin: 0 auto;
  }

  .carring-blur {
    width: 100%;
    top: 55%;
    bottom: auto;
    transform: translateY(-50%);
    height: 200px;
  }

  .appbox-image {
    margin: 40px 0 30px;
    padding-bottom: 40px;
    position: relative;
  }

  .appbox-image .img-responsive {
    width: 220px;
  }

  .mobile-carring {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: -1;
  }

  .about-empower {
    padding: 50px 0 60px;
    margin: 50px 0;
  }

  .empower-box {
    padding: 20px;
    margin-top: 30px;
  }

  .journey-slider {
    margin-right: 0;
    padding-right: 0;
  }

  .custom-arrows {
    margin-top: 22px;
  }

  .custom-arrows .default-arrowleft,
  .custom-arrows .default-arrowright {
    margin: 0 10px;
  }

  .about-reason,
  .about-team {
    margin: 40px 0 50px;
  }

  .aboutteam-box,
  .carpool-custom .img-responsive,
  .taxi-solut .moblimage-box {
    margin-top: 30px;
  }

  .carpool-head .row {
    padding: 40px 0;
  }

  .carpool-head p {
    margin: 10px 0 12px;
  }

  .carhead-image {
    position: unset;
    width: 270px;
    margin: 0 auto;
    display: block;
  }

  .card-conversation-image,
  .carpool-head .default-btn,
  .taxi-disbanner .animated-btn {
    margin-right: 10px;
  }

  .carpool-head .row img {
    width: 280px;
    margin-top: 20px;
  }

  .carlaunch-box label {
    font-size: 13px;
  }

  .carlaunch-box p {
    margin-bottom: 15px;
  }

  .carvehicle-box {
    padding: 20px;
    width: 100%;
  }

  .carvehicle-box ul {
    width: 219px;
  }

  .carvehicle-box ul li {
    margin-top: 20px;
  }

  .carvehicle-arrow {
    margin-left: 0;
    margin-top: 20px;
    transform: rotate(90deg);
  }

  .carsolution-model {
    padding: 20px;
    margin-top: 20px;
  }

  .carsolution-model ul li {
    padding: 12px;
    margin-top: 13px;
    width: 48%;
  }

  .carsolution-model ul li p {
    margin: 12px 0 0;
  }

  .cartechtab-link .nav-item,
  .cartechtab-point:last-child,
  .country-flag {
    margin-bottom: 20px;
  }

  .cartechtab-content {
    padding: 20px;
    margin-left: 0;
    margin-top: 30px;
  }

  .cartechtab-image {
    width: 250px;
  }

  .cartechtab-btn {
    margin-top: 20px !important;
  }

  .cartechtab-image-2 {
    width: 280px;
    right: -21px;
    float: right;
  }

  .carpool-custom {
    margin-top: 50px;
  }

  .carpool-custom p {
    margin: 8px 0 15px;
  }

  .carcustom-vector {
    width: 80%;
  }

  .carwork-box,
  .whitelabl-tabarea {
    margin-top: 40px;
  }

  .carwork-box span {
    font-size: 50px;
    margin-right: 10px;
  }

  .carwork-vector-2 {
    width: 180px;
  }

  .carwork-vector-4 {
    width: 90px;
  }

  .carpool-value .sub-heading {
    margin: 8px 0 0;
  }

  .carpool-start {
    padding: 40px 0;
    margin: 50px 0;
    background-size: 120% 100%;
  }

  .carpool-feature .sub-heading {
    margin: 8px 0 30px;
  }

  .carfeature-more a {
    margin: 0 5px;
  }

  .faq-accord .accordion-body {
    padding: 0 0 15px;
  }

  .faq-accord .accordion-button {
    line-height: 1.4;
    font-size: 15px;
  }

  .faq-accord .card:before {
    width: 101%;
  }

  .whitehead-detail label {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .whitehead-detail span {
    margin: 3px 0 8px;
    font-size: 15px;
  }

  .whitehead-image {
    width: 100%;
    margin: 30px auto 0;
  }

  .whitehead-vector-1 {
    top: -60px;
    width: 290px;
    opacity: 0.7;
  }

  .whitehead-vector-2 {
    top: 200px;
    width: 300px;
  }

  .whitelabl-tabarea .sub-heading {
    width: auto;
    font-size: 16px;
  }

  .whitetab-link {
    margin-top: 30px;
    flex-direction: column;
    background-size: contain;
    background-repeat: repeat;
  }

  .whitetab-link .nav-link {
    display: flex;
    align-items: center;
  }

  .whitetab-link .nav-link p {
    font-size: 18px;
    margin: 0 0 0 10px;
  }

  .whitetab-link .nav-item:after {
    width: 90%;
    height: 1.4px;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: none;
    background: linear-gradient(to right, #fff0, #0035e5, #fff0);
  }

  .whitetab-content {
    margin-top: 20px;
    padding: 20px 20px 0;
  }

  .whitetab-detail {
    padding-bottom: 25px;
  }

  .white-insidetab h3,
  .white-insidetab h4,
  .whitetab-detail h3,
  .whitetab-detail h4 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .app-butn img {
    width: 100px;
  }

  .whitemobilz-box {
    margin-top: 10px;
  }

  .whitelabl-platform {
    padding: 50px 0;
    margin-bottom: 50px;
  }

  .mobsol-accord .card {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .mobsol-accord .accordion-button {
    padding: 15px;
    font-size: 20px;
  }

  .mobsol-accord .accordion-body {
    padding: 0 15px 15px;
  }

  .mobsol-accord .accordion-button span {
    width: 25px;
    height: 25px;
  }

  .whitelabl-demo {
    padding: 30px 0;
    margin: 50px 0;
    background-position: top left;
    background-size: cover;
  }

  .whitelabl-demo .heading {
    font-size: 24px;
    margin: 5px 0 10px;
  }

  .labelinside-tablink {
    flex-direction: row;
    justify-content: space-between;
  }

  .labelinside-tablink .nav-item {
    margin-bottom: 10px;
    width: 31%;
  }

  .wlabel-tab {
    margin-top: 30px !important;
  }

  .whitetab-content:after {
    bottom: -50%;
  }

  .query-popup {
    padding: 0 !important;
  }

  .query-formbox {
    padding: 20px 15px;
  }

  .query-formbox .heading {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .pop-vector-3 {
    top: -30px;
    width: 300px;
  }

  .query-formbox .half-field {
    width: 100%;
    display: block;
    padding: 0 !important;
  }

  .whitelabl-solut.taxi-solut {
    padding-bottom: 50px;
  }

  .tax-gif {
    padding: 20px 0 0;
  }

  .taxi-disbanner {
    padding-top: 30px;
  }

  .taxi-service .web-booking-box {
    min-height: auto;
    margin: 30px 0 0;
  }

  .why-wait-area.taxi-label {
    background-position: center;
    margin-bottom: 50px;
  }

  .taxi-disbanner .taxi-btn {
    padding-bottom: 20px;
  }

  .featurehead-vector-1 {
    top: -20px;
    width: 300px;
  }

  .featurehead-vector-2 {
    width: 50px;
  }

  .featurtable-vector {
    width: 40px;
  }

  .feat-heading td:first-child {
    font-size: 14px;
    line-height: 1.3;
  }

  .feature-table table tr td {
    padding: 6px;
    font-size: 13px;
  }

  .feat-heading td:last-child,
  .feat-heading td:nth-last-child(2) { font-size: 12px; padding: 5px 0; width: 60px !important;}
  .feature-table table .table-HEAD td {font-size: 15px;}
  .feature-table table tr td:first-child {width: 35px;}
  .feature-table table tr td img {width: 12px;}
  .feature-table table .table-HEAD td:first-child:before { right: 16px; width: 30px;}
  .taxi-solut .solut-vector { right: 0; width: 80%; top: unset; bottom: 0;  }
  .taxi-solut .solut-vector1 {left: 0; width: 9%; top: -50px; }
  section.taxi-disbanner::before { height: 20%; background: linear-gradient(to bottom, #fff0 0, #fff 70%); }
  .admi-head-image { position: relative; width: 100%; top: auto; bottom: 0; transform: none; right: 0; margin: 30px auto 0; height: auto; padding: 0 12px;}
  .slider-section { padding-bottom: 0; min-height: auto; padding-top: 0;}
  .lyft-enterprises { padding: 50px 0; padding-bottom: 50px;}
  .admission-scr { padding: 50px 0; padding-top: 50px;}
  .admipage-vector { top: 0; left: 0; height: 110%; filter: blur(30px); z-index: -1;}
  .admipage-vector2 {display: none;}
  .lyfc-faq {padding-top: 0;}
  .lft-banner {padding: 120px 0; padding-bottom: 0;}
  .lyft-banner-btn { margin-bottom: 30px;}
  .lft-banner .heading strong {display: inline; padding-left: 5px;}
  .lft-banner .heading {font-weight: 600 !important;}
  .admission-scr.lyft-wheel-area {margin: 0; padding-top: 0;}
  .lyft-wheel-content .heading br {display: none;}
  .slider-section .slick-slide {padding: 30px 10px;}
  .media-section {padding: 50px 0;}

  /* restaurant page */
  .restro-banner.pharm-banner{padding-top: 30px;}
  .restro-industry-area{margin: 50px 0;}
  .restro-magic-details{margin-top: 20px;}
  .restro-magic-details h4{font-size: 24px;}
  .restro-magic-details .purple-gradiant{font-size: 12px;}
  .restro-margin-area{margin: 50px 0;}
  .restro-margin-head span{display: inline;}
  .restro-margin-count{margin: 50px 0;}
  .restro-margin-detail{margin-bottom: 20px;}
  .elearning-head .heading span{display: inline;}
  .restro-excellent-area{margin: 50px 0;}
  .restro-excellent-box{margin-top: 30px;}
}
